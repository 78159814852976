<template>
  <div class="next-date-account-modal">
    <sweet-modal
      ref="modal"
      overlay-theme="dark"
      width="900px">
      <div class="modal-header">청구 예정 내역</div>
      <div class="modal-content">
        <table class="table amt-table" style="width:100%">
          <thead>
          <tr>
            <th style="width:10%">구매일</th>
            <th style="width:10%">결제일</th>
            <th style="width:10%">구분</th>
            <th style="width:20%">내용</th>
            <th style="width:10%">플랜</th>
            <th style="width:10%">서비스 이용기간</th>
            <th style="width:10%">금액</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, item_idx) in account.list" :key="`item-${item_idx}`">
            <td>{{ datesFormat(item.created_time, 'date_3') }}</td>
            <td>{{ datesFormat(item.next_date, 'date_3') }}</td>
            <td>{{ appType(item) }}</td>
            <td><div style="white-space: pre-line">{{ name(item) }}</div></td>
            <td><span v-if="item.membership_name">{{ item.membership_name }}</span> {{ item.date_unit }}개월</td>
            <td>{{ period(item) }}</td>
            <td>{{ item.price | currency }}</td>
          </tr>
          </tbody>
        </table>
        <div class="grid-total body4 main">
          <div></div>
          <div>소계</div>
          <div>{{ account.next_price * 10 / 11 | currency }}</div>
          <div></div>
          <div class="main">부가세(10%)</div>
          <div>{{ account.next_price / 11 | currency }}</div>
          <div style="text-align: left">
            결제 수단
            <span style="margin-left:8px" v-if="account.bill">{{ account.bill !== '' ? account.bill.num + ' / ' + account.bill.card_name : '미등록'  }}</span>
          </div>
          <div class="body2-bold">총합계</div>
          <div class="body2-bold">{{ account.next_price | currency }}</div>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
  import { SweetModal } from 'sweet-modal-vue';
  export default {
    name: "NextDateAccountModal",
    components: {
      SweetModal
    },
    props: {
      account: {
        type: Object
      }
    },
    created() {

    },
    data() {
      return {}
    },
    methods: {
      open() {
        this.$refs.modal.open();
      },
      name(item) {
        let result = '';
        item.products.forEach(i => {
          if(result !== '') {
            result += '\n';
          }
          result += i.name
        });
        return result;
      },
      appType(item) {
        if(item.main) {
          return '솔루션';
        } else {
          return item.products[0].category;
        }
      },
      period(item) {
        return `${this.datesFormat(item.start_date, 'date_3')} ~ ${this.datesFormat(item.end_date, 'date_3')}`;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .amt-table th,
  .amt-table td
    text-align center
    padding 6px

  .amt-table th
    font-weight 500
    font-size 14px

  .amt-table td
    font-weight 400
    font-size 13px

  .amt-table tr
    border-bottom 1px solid #dddddd

  .amt-table tbody tr:last-child
    border-bottom 1px solid #000000

  .modal-header
    text-align left
    margin-bottom 20px
    font-size 21px

  .grid-total
    display grid
    grid-template-columns 1fr 80px 120px
    text-align right
    padding 16px 8px 0
    div
      padding 2px 0
</style>
<style lang="stylus">
  .next-date-account-modal
    .sweet-modal.is-alert .sweet-content
      padding 32px 24px
</style>
